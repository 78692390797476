import styled from '@mui/material/styles/styled';

const productDetailsImageHeight = 300;
const productDetailsTableMaxHeightBuffer = 100;
export const productDetailsProductNameContainerHeight = 26 + 7; // 26 height + 7 margin

export const ProductDetailsContainer = styled('div')`
  max-height: calc(
    100vh -
      ${({ theme }) =>
        theme.var.headerHeight +
        productDetailsImageHeight +
        productDetailsProductNameContainerHeight +
        theme.var.summaryTableCellPadding * 2 +
        productDetailsTableMaxHeightBuffer}px
  );
  overflow: auto;
`;
