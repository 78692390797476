import { useAddinVersion } from '@mid-react-common/addins';
import { AboutInfoMenu, FlexContainer, LeftSection, RightSection } from '@mid-react-common/common';
import CachedIcon from '@mui/icons-material/Cached';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { isUndefined } from 'lodash';
import text from 'revit-update-content.text.json';
import { InstancesToUpdateHeaderTestIds } from 'tests/helpers/dataTestIds';

interface InstancesToUpdateHeaderProps {
  isReplaceAvailable?: boolean;
  handleReplaceClick: () => void;
  handleRefreshClick: () => void;
}

export const InstancesToUpdateHeader: React.FC<InstancesToUpdateHeaderProps> = ({
  isReplaceAvailable,
  handleReplaceClick,
  handleRefreshClick,
}) => {
  const theme = useTheme();

  const { addinVersion, desktopApplicationVersion } = useAddinVersion();

  return (
    <Box display="flex" padding={`${theme.var.paddingBase}px`} alignItems="center">
      <LeftSection>
        {!isUndefined(isReplaceAvailable) ? (
          isReplaceAvailable ? (
            <Button
              size="small"
              variant="contained"
              data-testid={InstancesToUpdateHeaderTestIds.replaceButton}
              onClick={handleReplaceClick}
            >
              {text.replace}
            </Button>
          ) : (
            <>
              <Typography alignSelf="center" data-testid={InstancesToUpdateHeaderTestIds.noUpdatesAvailable}>
                {text.noUpdatesAvailable}
              </Typography>
              <Tooltip
                placement="right-start"
                title={text.noUpdatesAvailableTooltip}
                arrow
                data-testid={InstancesToUpdateHeaderTestIds.noUpdatesAvailableTooltip}
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </>
          )
        ) : (
          <Typography variant="subtitle1">{text.selectInstanceToUpdate}</Typography>
        )}
      </LeftSection>
      <RightSection>
        <FlexContainer>
          <Tooltip arrow title={text.refreshOutdatedInstances}>
            <IconButton
              size="small"
              onClick={handleRefreshClick}
              data-testid={InstancesToUpdateHeaderTestIds.refreshOutdatedInstances}
            >
              <CachedIcon />
            </IconButton>
          </Tooltip>
          <AboutInfoMenu
            isTargetBlank
            webComponentVersion={import.meta.env.VITE_RVTUC_VERSION || ''}
            addinVersion={addinVersion}
            desktopApplicationVersion={desktopApplicationVersion}
          />
        </FlexContainer>
      </RightSection>
    </Box>
  );
};
