import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { styled } from '@mui/material/styles';

export const StyledTreeView = styled(TreeView)`
  padding-left: ${({ theme }) => theme.var.paddingBase}px;
  width: auto;
`;

export const TreeItemWrapper = styled(TreeItem)`
  .MuiTreeItem-content {
    width: auto;
  }
  .MuiTreeItem-label {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
`;
