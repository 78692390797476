import {
  mockProducts,
  mockProjectFolder1,
  mockProjectFolder1a,
  mockProjectFolder1b,
  mockProjectFolder2,
  mockProjectFolder1c,
  mockProjectFolder2a,
  mockProjectFolder2b,
} from 'mid-utils';
import { TreeItem } from './MIDTree.types';

export const mockFolderTreeItemWithProductChildren: TreeItem = {
  id: mockProjectFolder1b.urn,
  label: mockProjectFolder1b.title,
  value: mockProjectFolder1b.urn,
  isExpandable: false,
  children: [],
  path: [],
};

export const mockProjectFolder1aTreeItem: TreeItem = {
  id: mockProjectFolder1a.urn,
  label: mockProjectFolder1a.title,
  value: mockProjectFolder1a.urn,
  isExpandable: true,
  children: [],
  path: [],
};

export const mockProjectFolder1bTreeItem: TreeItem = {
  id: mockProjectFolder1b.urn,
  label: mockProjectFolder1b.title,
  value: mockProjectFolder1b.urn,
  isExpandable: true,
  children: [],
  path: [],
};

export const mockProjectFolder1cTreeItem: TreeItem = {
  id: mockProjectFolder1c.urn,
  label: mockProjectFolder1c.title,
  value: mockProjectFolder1c.urn,
  isExpandable: true,
  children: [],
  path: [],
};

export const mockProjectFolder1TreeItem: TreeItem = {
  id: mockProjectFolder1.urn,
  label: mockProjectFolder1.title,
  value: mockProjectFolder1.urn,
  isExpandable: true,
  children: [],
  path: [],
};

export const mockProjectFolder2TreeItem: TreeItem = {
  id: mockProjectFolder2.urn,
  label: mockProjectFolder2.title,
  value: mockProjectFolder2.urn,
  isExpandable: true,
  children: [],
  path: [],
};

export const mockProjectFolder2aTreeItem: TreeItem = {
  id: mockProjectFolder2a.urn,
  label: mockProjectFolder2a.title,
  value: mockProjectFolder2a.urn,
  isExpandable: true,
  children: [],
  path: [],
};

export const mockProjectFolder2bTreeItem: TreeItem = {
  id: mockProjectFolder2b.urn,
  label: mockProjectFolder2b.title,
  value: mockProjectFolder2b.urn,
  isExpandable: true,
  children: [],
  path: [],
};

export const mockProjectFoldersTreeItems: TreeItem[] = [mockProjectFolder1TreeItem, mockProjectFolder2TreeItem];
export const mockProjectFoldersWithAccBridge: TreeItem[] = [mockProjectFolder2aTreeItem, mockProjectFolder2bTreeItem];

export const mockProductTreeItem: TreeItem = {
  id: mockProducts[0].contentId as string,
  label: mockProducts[0].name,
  value: mockProducts[0].contentId as string,
  children: [],
  isExpandable: false,
  path: [],
};

export const mockNormalizedTreeForFolders = {
  [mockProjectFolder1.urn]: {
    childIds: [],
    id: mockProjectFolder1.urn,
    label: mockProjectFolder1.title,
    original: mockProjectFolder1TreeItem,
  },
  [mockProjectFolder2.urn]: {
    childIds: [],
    id: mockProjectFolder2.urn,
    label: mockProjectFolder2.title,
    original: mockProjectFolder2TreeItem,
  },
};
