import { EMPTY_STATE_ILLUSTRATION_TYPES, MIDEmptyState, TableEmptyStateWrapper } from '@mid-react-common/common';
import text from 'revit-update-content.text.json';
import { InstancesToUpdateHeader } from '../InstancesToUpdateHeader/InstancesToUpdateHeader';
import { InstancesToUpdateGrid } from './InstancesToUpdate.style';
import { getColumns } from './InstancesToUpdate.utils';
import { useInstancesToUpdate } from './useInstancesToUpdate';

const InstancesToUpdate: React.FC = () => {
  const {
    isFetching,
    tableData,
    errorState,
    isReplaceAvailable,
    handleReplaceClick,
    handleInstanceSelection,
    handleRefreshOutdatedInstances,
  } = useInstancesToUpdate();

  const renderEmptyState = () => (
    <TableEmptyStateWrapper>
      <MIDEmptyState
        title={errorState ? text.notificationFetchAllMidInstanceDataFailed : text.notificationNoMidInstancesFound}
        illustrationType={errorState ? EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR : undefined}
      />
    </TableEmptyStateWrapper>
  );

  return (
    <>
      <InstancesToUpdateHeader
        isReplaceAvailable={isReplaceAvailable}
        handleReplaceClick={handleReplaceClick}
        handleRefreshClick={handleRefreshOutdatedInstances}
      />
      <InstancesToUpdateGrid
        onRowSelectionModelChange={handleInstanceSelection}
        loading={isFetching}
        disableMultipleRowSelection
        hideFooterSelectedRowCount
        autoPageSize
        pagination
        density="compact"
        getRowId={(row) => row.revitId}
        columns={getColumns()}
        rows={tableData}
        slots={{
          noRowsOverlay: renderEmptyState,
        }}
      />
    </>
  );
};

export default InstancesToUpdate;
