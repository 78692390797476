import { Environment } from 'mid-types';
import { EnvError } from 'mid-utils';
import { browserApiService } from '../../services';

export const getEnvironment = async (): Promise<Environment> => {
  const env = await browserApiService.getEnvironment();
  if (!env) {
    throw new EnvError("Couldn't retrieve environment from host application.");
  }

  return env;
};
