import { styled, css } from '@mui/material/styles';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';

export const MIDDropdownCircularProgress = styled(CircularProgress)<CircularProgressProps>(({ theme }) => ({
  marginLeft: theme.var.marginBase,
}));

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== '$responsive',
})<{ width?: string; $responsive?: boolean }>`
  ${({ $responsive, width }) =>
    $responsive
      ? css`
          min-width: ${width};
        `
      : css`
          width: ${width};
        `}
`;

export const styledPaperPopover = (maxHeight?: number): React.CSSProperties => {
  if (!maxHeight) {
    return {};
  }

  const dropdownHeight = `calc(100vh - ${maxHeight}px)`;
  return {
    maxHeight: dropdownHeight,
  };
};
