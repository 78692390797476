import { FlexContainer } from '@mid-react-common/common';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import React from 'react';
import text from '../../addins.text.json';

interface CreateNewFolderModalProps {
  newFolderName: string | undefined;
  setNewFolderName: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedFolderLabel: string | number | undefined;
  isCreatingFolder: boolean;
}

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  right: ${({ theme }) => `${theme.var.marginBase * 4}px`};
  margin-left: ${({ theme }) => `${theme.var.marginBase}px`};
`;

const CreateNewFolderModal: React.FC<CreateNewFolderModalProps> = ({
  newFolderName = '',
  setNewFolderName,
  selectedFolderLabel,
  isCreatingFolder,
}) => {
  const handleFolderTitleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFolderName(event.target.value);
  };

  return (
    <>
      <p>
        {text.createNewFolderUnderFollowingFolderMessage} <b>{selectedFolderLabel}</b>.
        <br />
        {text.enterNameNewFolderMessage}
      </p>
      <FlexContainer justifyContent="space=between">
        <TextField
          inputProps={{ 'data-testid': 'input-new-folder-name' }}
          label={text.inputLabelNewFolderName}
          variant="outlined"
          value={newFolderName}
          onChange={handleFolderTitleInputChange}
        />
        {isCreatingFolder && <StyledCircularProgress />}
      </FlexContainer>
    </>
  );
};

export default CreateNewFolderModal;
