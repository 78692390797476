import { AuthenticationError } from 'mid-utils';
import text from '../../mid-addin-lib.text.json';
import { webview2BrowserApiService } from '../../services';

/**
 *
 * @returns 3 legged auth token of the user
 */
export const getCurrentAuthToken = async (): Promise<string> => {
  const token = await await webview2BrowserApiService.getOAuth2Token();
  if (!token) {
    throw new AuthenticationError(text.unauthorizedAccessMessage);
  }

  return token;
};
