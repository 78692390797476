import { getAssemblyVersion, getDesktopApplicationVersionNumber } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { useState, useCallback, useEffect } from 'react';

interface UseAddinVersionState {
  addinVersion: string | undefined;
  desktopApplicationVersion: string | undefined;
}

export const useAddinVersion = (): UseAddinVersionState => {
  const [addinVersion, setAddinVersion] = useState<string | undefined>();
  const [desktopApplicationVersion, setDesktopApplicationVersion] = useState<string | undefined>();

  const setAddinVersionAndDesktopApplicationVersion = useCallback(async () => {
    const addinVersion = await getAssemblyVersion();
    const desktopVersion = await getDesktopApplicationVersionNumber();

    setAddinVersion(addinVersion);
    setDesktopApplicationVersion(desktopVersion);
  }, [setAddinVersion, setDesktopApplicationVersion]);

  useEffect(() => {
    try {
      setAddinVersionAndDesktopApplicationVersion();
    } catch (error) {
      logError(error);
    }
  }, [setAddinVersionAndDesktopApplicationVersion]);

  return {
    addinVersion,
    desktopApplicationVersion,
  };
};
